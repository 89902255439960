.video-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

#profile-vid-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.profile-page{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-card-container{
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.video-box{
    border-radius: 15px;
    width: 350px;
    height: 200px;
}
.feed-video-box{
    width: 168px;
    height: 94px;
    border-radius: 10px;
}
.video-title{
    font-weight: bold;
    width: 280px;
    /* word-wrap: break-word; */
}

.time-elapsed{
    color: #666361;
    font-size: 15px;
}

.auth-button{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.user-page-bt{
    cursor: pointer;
    /* margin: 12px 20px 0px 20px; */
    border-radius: 3px;
    width: 50px;
    height: 20px;
    border: none;
    background-color: #287ce9;
    color: white;
    text-align: center;
    font-size: 15px;
}

#banner{
    width: 1400px;
    height: 350px;
    margin-bottom: 50px;
    border-radius: 15px;

}

#ul-h3{
    text-align: center;

}

#file-input{
    margin: 5px;
    width: 290px;
}

input[type=file]::file-selector-button{
    background-color: #fff;
    color: #000;
    border: 0px;
    border-right: 1px solid #e5e5e5;
    padding: 10px 15px;
    margin-right: 20px;
    transition: .5s
}
input[type=file]::file-selector-button:hover {
    background-color: #eee;
    border: 0px;
    border-right: 1px solid #e5e5e5;
  }

#ulp-icon{
    width: 300px;
    margin-bottom: 40px;
}

.error-list{
    color: red;
}

.input-file-container{
    border: 1px solid #e5e5e5;
    margin: 20px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
}
#li-form-uploadPG{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 310px;
}
.file_upload_title {
    margin-left: 20px;
    font-size: 14px;
}

.vid-des{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    max-width: 1000px;
    max-height: 120px;
    border-radius: 10px;
    background-color: #f2f2f2;
    margin-bottom: 50px;
}
#des-p{
    margin-left: 20px;
    width: 950px;
}

#lc-h{
    margin-bottom: 50px;
}

.side-bar{
    /* border: #287ce9 solid 1px; */
    width: 300px;
    margin-bottom: 100px;
    /* margin-right: 40px; */

}

.main-page-wrapper{
    display: flex;
    flex-direction: row;
}

.sidebarcontent{
    font-size: 35px;
    cursor: pointer;
    font-weight: light;
    margin-right: 15px;
    border-radius: 100px;
    width: 45px;
}
.line-break-sb{
    background-color: rgb(200, 200, 200);
    width: 160px;
    height: 1px;
    margin-bottom: 10px;
    margin-top: 30px;
}
#vdp-wrapper{
    display: flex;
    flex-direction: row;
}
#up-sb-wrapper{
    display: flex;
    flex-direction: row;
}
#upload-sb-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* justify-content: space-around; */
}

.su-pg-wrapper{
    display: flex;
    flex-direction: row;
}
.nav-icon-holder{
    display: flex;
    flex-direction: row;
    margin-left: 12px;
}
.nav-icons{
    width: 30px;
}
#manage-acct{
    width: 30px;
}
.sb-sub-header{
    color: #2b4960;
    margin-top: 40px;
    margin-bottom: 50px;
    margin-right: 0px;
    margin-left: 15px;
    font-weight: 600;
}

#airbpib{
    width: 36px;
    height: 32px;
    margin-top: 2px;
}
#robinhold{
    width: 35px;
    height: 36px;
}
#python{
    width: 38px;
    height: 38px;
}
#flask{
    width: 38px;
    height: 34px;
    margin-left: 2px;
}
#javascript{
    width: 45px;
    height: 45px;

}
#react{
    height: 35px;
}
#redux{
    width: 35px;
    height: 35px;
}
#aws{
    width: 38px;
}
#python-t{
    margin-left: 36px;
    margin-top: 6px;
}
#flask-t{
    margin-left: 36px;
    margin-top: 6px;
}
#javascript-t{
    margin-left: 26px;
    margin-top: 10px;
}
#react-t{
    margin-left: 33px;
    margin-top: 6px;
}
#redux-t{
    margin-left: 36px;
    margin-top: 6px;
}
#aws-t{
    margin-left: 32px;
    margin-top: 6px;
}

 a {
    text-decoration: none;
    color: inherit;
 }

 .video-card-info{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
 }


.vid-info-right{
    display: flex;
    flex-direction: column;
    /* margin-left: 20px; */
}
.vid-card-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: #287ce9 solid 1px; */
}
/* .vid-card-img >img{
    border-radius: 50%;
    background-size: cover;
    background-color: #287ce9;
} */
.vid-detail-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
    align-items: center;
    max-width: 960px;
}
#vid-dh-title{
    font-size: 20px;
    font-weight: bold;
}
.like-bttn{
    display: flex;
    flex-direction: row;
    border: none;
    border-radius: 50px;
    width: 80px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}
#thumb-up{
    width: 23px;
    margin-right: 5px;
}
#thumb-down{
    width: 23px;
    margin-right: 5px;
}
.like-btn-wrapper{
    display: flex;
    flex-direction: row;
    height: 37px;
    margin-left: 20px;
}

.feed-container{
    /* border: #287ce9 1px solid; */
    width: 400px;
    margin-left: 50px;
}

.detail-page-content{
    margin-bottom: 100px;
    width: 1000px;
}


.feed-vid-card{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.feed-vid-info{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 6px;
}
#feed-vid-title{
    font-weight: bold;
}
#feed-vid-owner{
    color: #8a8a8a;
    font-size: 12px;
}
#feed-vid-time{
    color: #8a8a8a;
    font-size: 12px;

}
.vid-btm-info{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.vid_card_name_wrapper{
    display: flex;
    flex-direction: row;
}
#verified_check{
    height: 13px;
    width: 13px;
    margin-top: 2px;
    margin-left: 2px;
}

.pfp_default{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 300;
    color:white
}
