.comment-textarea{
    width: 990px;
    /* border: none; */
    border-top: none;
    border-right:none ;
    border-left: none;
    border-bottom: solid grey 1px;
    height: 30px;
    /* outline-width: 0; */
}

input.comment-textarea:focus{
    outline-width: 1px;

}

/* .line-break{
    background-color: rgb(200, 200, 200);
    width: 800px;
    height: 1px;

    margin-bottom: 10px;
} */

.ce-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* margin-right: 260px; */
}

.ce-button{
    cursor: pointer;
    margin: 20px;
    border-radius: 30px;
    width: 100px;
    height: 40px;
    border: none;
}
.ce-button:hover{
    background-color: #8b8a8a;
}

.cm-fineprint{
    color: #9f8380;
    font-size: 13px;
    /* margin-bottom:5px; */
    /* margin-top: 10px;
    margin-left: 10px; */
}
.leave-cm-container{
    display: flex;
    flex-direction: column;
    width: 990px;

}
#comment-content{
    margin: 10px 0;
}

.single-comment-container{
    display: flex;
    flex-direction: column;
    width:990px;
}
.s-ce-button{
    cursor: pointer;
    margin: 10px;
    border-radius: 30px;
    width: 50px;
    height: 30px;
    border: none;
}
.sd-ce-button{
    cursor: pointer;
    margin: 10px;
    border-radius: 30px;
    width: 55px;
    height: 30px;
    border: none;
    background-color: #287ce9;
    color: white;
}

.s-ce-button:hover{
    background-color: #8b8a8a;
}

/* .s-ce-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: none;
    border-bottom: solid 1px #cac7c7;
} */

.is-ce-button{
    cursor: pointer;
    margin: 10px;
    border-radius: 30px;
    width: 60px;
    height: 30px;
    border: none;
    background-color: #287ce9;
    color: white;
    text-align: center;
}

.cmt-info-wrapper{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-self: flex-start;
    align-items: center;
}

.cmt-info-wrapper > * {
    margin-right: 10px;
}

#cmt-username{
    /* margin-top: 7px; */
    font-weight: bold;
}
.cmt-likebtn-wrapper{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-bottom: 30px;
}

.cmt-like-bttn{
    width: 25px;
    margin-right: 5px;
    cursor: pointer;
}
#cmt-morevert{
    height: 26px;
    cursor: pointer;

}

.edit-cmt-container{
    display: flex;
    flex-direction: column;
    border: solid rgb(187, 200, 228, 0.3) 0.1px;
    box-shadow: 0px 0px 10px rgb(187, 200, 228, 0.7);
    background-color: white;
    border-radius: 15px;
    z-index: 5000;
    position: absolute;
    margin-right: 20px;
    height: 100px;
    width: 105px;


}
.cmt-content-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.edit-pic{
    width: 25px;
    margin: 10px;
}
.edit-cmt-col{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-radius: 15px;


}

.edit-cmt-col:hover{
    background-color: #8b8a8a;
}


#edit-cmt-edit{
    margin-top: 14px;
}
#cmt-delete-txt{
    margin-top: 13px;
}

.cmt-likebtn-container{
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.cmt_pf_pic{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.single_cmt_side_divider{
    display: flex;
    flex-direction: row;
}

.single_cmt_side_divider > * {
    margin-right: 15px;
}

.cmt_editing_container{

}
