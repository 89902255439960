.login-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: solid #e8eaec 2px;
    border-radius: 10px;
    margin-top: 100px;
    margin-bottom: 300px;
    width: 500px;
    height: 600px;
}
.login-page-wrapper{
    /* display: flex;
    justify-content: space-between;
    align-items: center; */

}

.li-form{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.form-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
}

.lg-h2{
    text-align: center;
    font-size: 28px;
    margin-bottom: 0%;
    margin-top: 10px;
}

#lg-p{
    text-align: center;
    margin-bottom: 30px;
}

.lg-input-field{
    border: #dee0e4 solid 1.7px;
    border-radius: 5px;
    height: 30px;
    width: 300px;
    margin: 15px;
}

.logo-su-lg{
    width: 200px;
}
.lg-button{
    cursor: pointer;
    margin: 12px 20px 0px 20px;
    border-radius: 3px;
    width: 100px;
    height: 40px;
    border: none;
    background-color: #287ce9;
    color: white;
    font-weight: bold;
}

.su-button-container{
    margin-top: 20px;
}

.lg-pg-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
}
