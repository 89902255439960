.edit-video-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: solid red 1px; */
    justify-content: center;
    text-align: end;
}

.edit-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: end;
}
