@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap');
*{
  font-family: 'Roboto', sans-serif;
}

body{
  display: flex;
  justify-content: center;
  align-items: center;
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
    height: 60px;
    width: 100%;
    z-index: 1000;


}

.nav-wrapper{

}

.logo{
    width: 150px;
    margin-left: 10px;
    margin-top: 3px;
}

.dropdownMenulogin {
    border: solid rgb(187, 200, 228, 0.3) 0.1px;
    box-shadow: 0px 0px 10px rgb(187, 200, 228, 0.7);
    background-color: white;
    border-radius: 15px;
    top: 83px;
    position: absolute;
    height: 145px;
    width: 200px;
    z-index: 5000;
    padding-top: 11px;

  }


  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* margin: -40px 0px 0px 0px; */
  }

  .loginselect {
    margin-bottom: 6px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 15px;
    height: 20px;
  }
  .loginselect:hover {
    background-color: rgb(187, 200, 228, 0.3);
    cursor: pointer;
  }




  /* ==================================== */




  .headerLine {
    width: 100%;
    height: 1.5px;
    margin-top: 22px;
    background-color: rgb(187, 200, 228, 0.3);
  }

  .sessionlink {
    padding-right: 10vw;
  }


  .pfbutton {
    width: 100px;
    justify-self: left;
    background-color: white;
    border: 1px solid rgb(96, 123, 198, 0.2);
    border-radius: 20px;
    transition: box-shadow 0.5s;
    height: 40px;
    margin-right: 40px;
  }

  .pfbutton:hover {
    box-shadow: 1px 2px 5px rgb(187, 200, 228, 0.7);
    cursor: pointer;
  }


  .dropdownMenulogin {
    border: solid rgb(187, 200, 228, 0.3) 0.1px;
    box-shadow: 0px 0px 10px rgb(187, 200, 228, 0.7);
    background-color: white;
    border-radius: 15px;
    top: 56px;
    position: absolute;
    height: fit-content;
    width: 150px;
    z-index: 5000;
    padding-top: 11px;
    margin-right: 18px;
  }

  #signin{
    margin-bottom: 50px;
  }
  .nav-left{
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    height: 100%;
  }

  .material-symbols-outlined {
    cursor: pointer;
    color: grey;
    margin-right: 15px;
    border-radius: 100px;
    width: 40px;
  }

  .material-symbols-outlined:hover {
    background-color: #f2f2f2;
  }


.sb-home{
  width: 40px;
}
.sb-home-wrapper{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 10px;
  width: 200px;
  height: 40px;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-right: 18px;

}
#sbHomeWrapper1st{
  margin-top: 1px;
}
.sb-home-wrapper:hover{
  background-color: #f2f2f2;
  font-weight: bold;
}
.sb-home-txt{
  margin-top: 12px;
  margin-left: 50px;
  font-size: 18px;
}
.sb-home-txt-1{
  margin-top: 7px;
  margin-left: 40px;
  font-size: 18px;
}



#linkedin{
  width: 30px;
  height: 30px;
  margin-top: 6px;
  margin-left: 5px;
}
#github{
  width: 33px;
  height: 33px;
  margin-left: 3px;
  margin-top: 3px;
}

.sb-content{
  margin-left: 30px;
  width: 160px;
}
.cmt-content-wrapper{
  display: flex;
  flex-direction: row;
}

.searchall{

}

.searchInputs{
  border: #d3d3d3 1px solid;
  width: 540px;
  height: 38px;
  border-radius: 40px 0 0 40px ;
  padding-left: 15px;
  /* color: #91949e; */
  font-size: 17px;
  /* font-weight: lighter; */
}
.searchInputs:focus{
  outline: none;
  border-color: #568fd9;
  box-shadow: 1px 1px 3px #91949e;
}

.search-wrapper{
  display: flex;
  flex-direction: row;
}

.search-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0 40px 40px 0;
  border: #d3d3d3 1px solid;
  height: 40px;
  width: 64px;
  background-color: #f8f8f8;
  border-left-style: none;
  cursor: pointer;
}
#search-icon{
  width: 30px;
  height: 30px;
}
.data-result{
  z-index: 2000;
  position: relative;
  background-color: white;
  border-radius: 20px;
  max-width: 570px;
  /* padding-left: 30px; */

}
#search-res-pic{
width: 35px;
height: 35px;
margin-right: 10px;
}
#search-res-text{
  font-weight: bold;
  font-size: 15px;
}
.res-row{
  /* margin-left: 30px; */
}
.data-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  margin: 0px 10px;
}
.data-item:hover{
  background-color: #f2f2f2;
}

#sb-space{
  width: 10px;
}

.nav-right{
  display: flex;
  flex-direction: row;
}
#uploadIcon{
  width: 40px;
  height: 35px;
  /* margin-right: 20px; */
  margin-top: 3px;
  cursor: pointer;
  border-radius: 50%;
}
#uploadIcon:hover{
  background-color: #f2f2f2;
}
.hide{
  display: none;
  border-radius: 5px;
  /* border: #91949e 1px solid; */
  position:absolute;
  margin-top: 50px;
  background-color: rgb(115, 112, 112);
  width: 70px;
  height: 30px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  color: white;
}

#uploadIcon:hover + .hide{
  display: flex;

}

#create-vid-wrapper{
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
}
