.pf_pg_video_card_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    margin-top: 50px;
}
.pf_pg_video_card_container{
    margin: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

#pf_pg_video_thumbnail{
    width: 350px;
    height: 200px;
    border-radius: 15px;
}

.channel_info_container{
    height: 230px;
    width: 1280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.channelOwner_profilePic{
    height: 160px;
    width: 160px;
    border-radius: 50%;
}
.info_box_rs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 600px;
    color: #666d74;
    gap: 15px;
}
.pfp_channel_name{
    font-size: 36px;
    color: black;
    font-weight: bold;
}
.pfp_sub_btn_unsb{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: black;
    height: 35px;
    width: 95px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}
.pfp_sub_btn_sb{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #f2f2f2;
    height: 35px;
    width: 120px;
    font-weight: bold;
    font-size: 14px;
}
#pfp_sub_btn_bell{
    margin: 0;
    width: 25px;
    font-size: 20px;
}
