@import url(https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap);
/* TODO Add site wide styles */
.login-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: solid #e8eaec 2px;
    border-radius: 10px;
    margin-top: 100px;
    margin-bottom: 300px;
    width: 500px;
    height: 600px;
}
.login-page-wrapper{
    /* display: flex;
    justify-content: space-between;
    align-items: center; */

}

.li-form{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.form-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
}

.lg-h2{
    text-align: center;
    font-size: 28px;
    margin-bottom: 0%;
    margin-top: 10px;
}

#lg-p{
    text-align: center;
    margin-bottom: 30px;
}

.lg-input-field{
    border: #dee0e4 solid 1.7px;
    border-radius: 5px;
    height: 30px;
    width: 300px;
    margin: 15px;
}

.logo-su-lg{
    width: 200px;
}
.lg-button{
    cursor: pointer;
    margin: 12px 20px 0px 20px;
    border-radius: 3px;
    width: 100px;
    height: 40px;
    border: none;
    background-color: #287ce9;
    color: white;
    font-weight: bold;
}

.su-button-container{
    margin-top: 20px;
}

.lg-pg-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
}

.su-pg-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.signup-page{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 450px; */
    /* position: absolute; */
    /* min-height: 100vh; */
}

.signup-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: solid #e8eaec 2px;
    border-radius: 10px;
    /* margin-top: 100px; */
    margin-bottom: 300px;
    width: 1000px;
    height: 700px;
}

.left-side{
    /* border: solid green 1px; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* align-items: center; */

}

.right-side{
    /* border: 1px solid blue; */
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.su-form{
    display: flex;
    flex-direction: column;

}
.nameinfo-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.password-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 20px 5px 20px;
}

.input-wrapper{
    margin: 20px;
}

.f-input-field{
    width: 98%;
}


.input-field{
    border: #dee0e4 solid 1.7px;
    border-radius: 5px;
    height: 30px;
    width: 100%;
}

.logo-su{
    width: 200px;
    margin-left: 20px;
}
.rightSide-text{
    margin-left: 25px;
}
#p-text{
    margin-top: 0%;
    margin-bottom: 20px;
}
#h3text{
    margin-bottom: 10px;
}
.fine-print{
    color: #a08a7b;
    font-size: 12px;
    margin-left: 10px;
    margin-top: 5px;
}
.fine-print-p{
    color: #a08a7b;
    font-size: 12px;
    margin-top: 0%;
    margin-left: 25px;
}

.su-button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.su-button-content{
    margin: 25px;
}
#su-button-p{
    font-weight: bold;
    color: #287ce9;
}

#su-button-s{
 cursor: pointer;
 margin: 12px 20px 0px 20px;
 border-radius: 3px;
 width: 100px;
 height: 40px;
 border: none;
 background-color: #287ce9;
 color: white;
 font-weight: bold;
}

#rs-text{
    text-align: center;
    font-size: 30px;
    color: #786259;
    font-weight: -100;
}

*{
  font-family: 'Roboto', sans-serif;
}

body{
  display: flex;
  justify-content: center;
  align-items: center;
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
    height: 60px;
    width: 100%;
    z-index: 1000;


}

.nav-wrapper{

}

.logo{
    width: 150px;
    margin-left: 10px;
    margin-top: 3px;
}

.dropdownMenulogin {
    border: solid rgb(187, 200, 228, 0.3) 0.1px;
    box-shadow: 0px 0px 10px rgb(187, 200, 228, 0.7);
    background-color: white;
    border-radius: 15px;
    top: 83px;
    position: absolute;
    height: 145px;
    width: 200px;
    z-index: 5000;
    padding-top: 11px;

  }


  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* margin: -40px 0px 0px 0px; */
  }

  .loginselect {
    margin-bottom: 6px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 15px;
    height: 20px;
  }
  .loginselect:hover {
    background-color: rgb(187, 200, 228, 0.3);
    cursor: pointer;
  }




  /* ==================================== */




  .headerLine {
    width: 100%;
    height: 1.5px;
    margin-top: 22px;
    background-color: rgb(187, 200, 228, 0.3);
  }

  .sessionlink {
    padding-right: 10vw;
  }


  .pfbutton {
    width: 100px;
    justify-self: left;
    background-color: white;
    border: 1px solid rgb(96, 123, 198, 0.2);
    border-radius: 20px;
    transition: box-shadow 0.5s;
    height: 40px;
    margin-right: 40px;
  }

  .pfbutton:hover {
    box-shadow: 1px 2px 5px rgb(187, 200, 228, 0.7);
    cursor: pointer;
  }


  .dropdownMenulogin {
    border: solid rgb(187, 200, 228, 0.3) 0.1px;
    box-shadow: 0px 0px 10px rgb(187, 200, 228, 0.7);
    background-color: white;
    border-radius: 15px;
    top: 56px;
    position: absolute;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 150px;
    z-index: 5000;
    padding-top: 11px;
    margin-right: 18px;
  }

  #signin{
    margin-bottom: 50px;
  }
  .nav-left{
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    height: 100%;
  }

  .material-symbols-outlined {
    cursor: pointer;
    color: grey;
    margin-right: 15px;
    border-radius: 100px;
    width: 40px;
  }

  .material-symbols-outlined:hover {
    background-color: #f2f2f2;
  }


.sb-home{
  width: 40px;
}
.sb-home-wrapper{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 10px;
  width: 200px;
  height: 40px;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-right: 18px;

}
#sbHomeWrapper1st{
  margin-top: 1px;
}
.sb-home-wrapper:hover{
  background-color: #f2f2f2;
  font-weight: bold;
}
.sb-home-txt{
  margin-top: 12px;
  margin-left: 50px;
  font-size: 18px;
}
.sb-home-txt-1{
  margin-top: 7px;
  margin-left: 40px;
  font-size: 18px;
}



#linkedin{
  width: 30px;
  height: 30px;
  margin-top: 6px;
  margin-left: 5px;
}
#github{
  width: 33px;
  height: 33px;
  margin-left: 3px;
  margin-top: 3px;
}

.sb-content{
  margin-left: 30px;
  width: 160px;
}
.cmt-content-wrapper{
  display: flex;
  flex-direction: row;
}

.searchall{

}

.searchInputs{
  border: #d3d3d3 1px solid;
  width: 540px;
  height: 38px;
  border-radius: 40px 0 0 40px ;
  padding-left: 15px;
  /* color: #91949e; */
  font-size: 17px;
  /* font-weight: lighter; */
}
.searchInputs:focus{
  outline: none;
  border-color: #568fd9;
  box-shadow: 1px 1px 3px #91949e;
}

.search-wrapper{
  display: flex;
  flex-direction: row;
}

.search-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0 40px 40px 0;
  border: #d3d3d3 1px solid;
  height: 40px;
  width: 64px;
  background-color: #f8f8f8;
  border-left-style: none;
  cursor: pointer;
}
#search-icon{
  width: 30px;
  height: 30px;
}
.data-result{
  z-index: 2000;
  position: relative;
  background-color: white;
  border-radius: 20px;
  max-width: 570px;
  /* padding-left: 30px; */

}
#search-res-pic{
width: 35px;
height: 35px;
margin-right: 10px;
}
#search-res-text{
  font-weight: bold;
  font-size: 15px;
}
.res-row{
  /* margin-left: 30px; */
}
.data-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  margin: 0px 10px;
}
.data-item:hover{
  background-color: #f2f2f2;
}

#sb-space{
  width: 10px;
}

.nav-right{
  display: flex;
  flex-direction: row;
}
#uploadIcon{
  width: 40px;
  height: 35px;
  /* margin-right: 20px; */
  margin-top: 3px;
  cursor: pointer;
  border-radius: 50%;
}
#uploadIcon:hover{
  background-color: #f2f2f2;
}
.hide{
  display: none;
  border-radius: 5px;
  /* border: #91949e 1px solid; */
  position:absolute;
  margin-top: 50px;
  background-color: rgb(115, 112, 112);
  width: 70px;
  height: 30px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  color: white;
}

#uploadIcon:hover + .hide{
  display: flex;

}

#create-vid-wrapper{
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
}

.edit-video-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: solid red 1px; */
    justify-content: center;
    text-align: end;
}

.edit-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: end;
}

/* #modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  #modal-content {
    position: absolute;
    background-color:white;
  } */


  #modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid green 1px; */
  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /* border: solid green 1px; */

  }

  #modal-content {
    position: absolute;
    background-color: rgba(240, 241, 242, 0.9);
    width: 450px;
    height: 260px;
    padding-bottom: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* border: solid green 1px; */

  }
  .edit-vid-wrapper{
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
  }
#edit-modal-header{
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
}
.edit-modal-iw{
display: flex;
flex-direction: row;
justify-content: start;
margin: 20px;

}

.edit-modal-label{
  margin-right: 20px;
}
#em-short-in{
  margin-left: 40px;
}

.em-input-field{
  border: #dee0e4 solid 1.7px;
  border-radius: 5px;
  height: 20px;
  width: 100%;
  /* margin-bottom: 5px; */
}
.em-submit-button{
  cursor: pointer;
  border-radius: 3px;
  width: 70px;
  height: 20px;
  border: none;
  background-color: #287ce9;
  color: white;
  text-align: center;
  font-size: 15px;
  margin-bottom: 10px;
}
.dm-button{
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  justify-content: space-around;
}
.comment-textarea{

}

.comment-textarea{
    width: 990px;
    /* border: none; */
    border-top: none;
    border-right:none ;
    border-left: none;
    border-bottom: solid grey 1px;
    height: 30px;
    /* outline-width: 0; */
}

input.comment-textarea:focus{
    outline-width: 1px;

}

/* .line-break{
    background-color: rgb(200, 200, 200);
    width: 800px;
    height: 1px;

    margin-bottom: 10px;
} */

.ce-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* margin-right: 260px; */
}

.ce-button{
    cursor: pointer;
    margin: 20px;
    border-radius: 30px;
    width: 100px;
    height: 40px;
    border: none;
}
.ce-button:hover{
    background-color: #8b8a8a;
}

.cm-fineprint{
    color: #9f8380;
    font-size: 13px;
    /* margin-bottom:5px; */
    /* margin-top: 10px;
    margin-left: 10px; */
}
.leave-cm-container{
    display: flex;
    flex-direction: column;
    width: 990px;

}
#comment-content{
    margin: 10px 0;
}

.single-comment-container{
    display: flex;
    flex-direction: column;
    width:990px;
}
.s-ce-button{
    cursor: pointer;
    margin: 10px;
    border-radius: 30px;
    width: 50px;
    height: 30px;
    border: none;
}
.sd-ce-button{
    cursor: pointer;
    margin: 10px;
    border-radius: 30px;
    width: 55px;
    height: 30px;
    border: none;
    background-color: #287ce9;
    color: white;
}

.s-ce-button:hover{
    background-color: #8b8a8a;
}

/* .s-ce-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: none;
    border-bottom: solid 1px #cac7c7;
} */

.is-ce-button{
    cursor: pointer;
    margin: 10px;
    border-radius: 30px;
    width: 60px;
    height: 30px;
    border: none;
    background-color: #287ce9;
    color: white;
    text-align: center;
}

.cmt-info-wrapper{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-self: flex-start;
    align-items: center;
}

.cmt-info-wrapper > * {
    margin-right: 10px;
}

#cmt-username{
    /* margin-top: 7px; */
    font-weight: bold;
}
.cmt-likebtn-wrapper{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-bottom: 30px;
}

.cmt-like-bttn{
    width: 25px;
    margin-right: 5px;
    cursor: pointer;
}
#cmt-morevert{
    height: 26px;
    cursor: pointer;

}

.edit-cmt-container{
    display: flex;
    flex-direction: column;
    border: solid rgb(187, 200, 228, 0.3) 0.1px;
    box-shadow: 0px 0px 10px rgb(187, 200, 228, 0.7);
    background-color: white;
    border-radius: 15px;
    z-index: 5000;
    position: absolute;
    margin-right: 20px;
    height: 100px;
    width: 105px;


}
.cmt-content-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.edit-pic{
    width: 25px;
    margin: 10px;
}
.edit-cmt-col{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-radius: 15px;


}

.edit-cmt-col:hover{
    background-color: #8b8a8a;
}


#edit-cmt-edit{
    margin-top: 14px;
}
#cmt-delete-txt{
    margin-top: 13px;
}

.cmt-likebtn-container{
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.cmt_pf_pic{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.single_cmt_side_divider{
    display: flex;
    flex-direction: row;
}

.single_cmt_side_divider > * {
    margin-right: 15px;
}

.cmt_editing_container{

}

.pf_pg_video_card_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    margin-top: 50px;
}
.pf_pg_video_card_container{
    margin: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

#pf_pg_video_thumbnail{
    width: 350px;
    height: 200px;
    border-radius: 15px;
}

.channel_info_container{
    height: 230px;
    width: 1280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.channelOwner_profilePic{
    height: 160px;
    width: 160px;
    border-radius: 50%;
}
.info_box_rs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 600px;
    color: #666d74;
    grid-gap: 15px;
    gap: 15px;
}
.pfp_channel_name{
    font-size: 36px;
    color: black;
    font-weight: bold;
}
.pfp_sub_btn_unsb{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: black;
    height: 35px;
    width: 95px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}
.pfp_sub_btn_sb{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #f2f2f2;
    height: 35px;
    width: 120px;
    font-weight: bold;
    font-size: 14px;
}
#pfp_sub_btn_bell{
    margin: 0;
    width: 25px;
    font-size: 20px;
}

.video-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

#profile-vid-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.profile-page{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-card-container{
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.video-box{
    border-radius: 15px;
    width: 350px;
    height: 200px;
}
.feed-video-box{
    width: 168px;
    height: 94px;
    border-radius: 10px;
}
.video-title{
    font-weight: bold;
    width: 280px;
    /* word-wrap: break-word; */
}

.time-elapsed{
    color: #666361;
    font-size: 15px;
}

.auth-button{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.user-page-bt{
    cursor: pointer;
    /* margin: 12px 20px 0px 20px; */
    border-radius: 3px;
    width: 50px;
    height: 20px;
    border: none;
    background-color: #287ce9;
    color: white;
    text-align: center;
    font-size: 15px;
}

#banner{
    width: 1400px;
    height: 350px;
    margin-bottom: 50px;
    border-radius: 15px;

}

#ul-h3{
    text-align: center;

}

#file-input{
    margin: 5px;
    width: 290px;
}

input[type=file]::file-selector-button{
    background-color: #fff;
    color: #000;
    border: 0px;
    border-right: 1px solid #e5e5e5;
    padding: 10px 15px;
    margin-right: 20px;
    transition: .5s
}
input[type=file]::file-selector-button:hover {
    background-color: #eee;
    border: 0px;
    border-right: 1px solid #e5e5e5;
  }

#ulp-icon{
    width: 300px;
    margin-bottom: 40px;
}

.error-list{
    color: red;
}

.input-file-container{
    border: 1px solid #e5e5e5;
    margin: 20px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
}
#li-form-uploadPG{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 310px;
}
.file_upload_title {
    margin-left: 20px;
    font-size: 14px;
}

.vid-des{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    max-width: 1000px;
    max-height: 120px;
    border-radius: 10px;
    background-color: #f2f2f2;
    margin-bottom: 50px;
}
#des-p{
    margin-left: 20px;
    width: 950px;
}

#lc-h{
    margin-bottom: 50px;
}

.side-bar{
    /* border: #287ce9 solid 1px; */
    width: 300px;
    margin-bottom: 100px;
    /* margin-right: 40px; */

}

.main-page-wrapper{
    display: flex;
    flex-direction: row;
}

.sidebarcontent{
    font-size: 35px;
    cursor: pointer;
    font-weight: light;
    margin-right: 15px;
    border-radius: 100px;
    width: 45px;
}
.line-break-sb{
    background-color: rgb(200, 200, 200);
    width: 160px;
    height: 1px;
    margin-bottom: 10px;
    margin-top: 30px;
}
#vdp-wrapper{
    display: flex;
    flex-direction: row;
}
#up-sb-wrapper{
    display: flex;
    flex-direction: row;
}
#upload-sb-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* justify-content: space-around; */
}

.su-pg-wrapper{
    display: flex;
    flex-direction: row;
}
.nav-icon-holder{
    display: flex;
    flex-direction: row;
    margin-left: 12px;
}
.nav-icons{
    width: 30px;
}
#manage-acct{
    width: 30px;
}
.sb-sub-header{
    color: #2b4960;
    margin-top: 40px;
    margin-bottom: 50px;
    margin-right: 0px;
    margin-left: 15px;
    font-weight: 600;
}

#airbpib{
    width: 36px;
    height: 32px;
    margin-top: 2px;
}
#robinhold{
    width: 35px;
    height: 36px;
}
#python{
    width: 38px;
    height: 38px;
}
#flask{
    width: 38px;
    height: 34px;
    margin-left: 2px;
}
#javascript{
    width: 45px;
    height: 45px;

}
#react{
    height: 35px;
}
#redux{
    width: 35px;
    height: 35px;
}
#aws{
    width: 38px;
}
#python-t{
    margin-left: 36px;
    margin-top: 6px;
}
#flask-t{
    margin-left: 36px;
    margin-top: 6px;
}
#javascript-t{
    margin-left: 26px;
    margin-top: 10px;
}
#react-t{
    margin-left: 33px;
    margin-top: 6px;
}
#redux-t{
    margin-left: 36px;
    margin-top: 6px;
}
#aws-t{
    margin-left: 32px;
    margin-top: 6px;
}

 a {
    text-decoration: none;
    color: inherit;
 }

 .video-card-info{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
 }


.vid-info-right{
    display: flex;
    flex-direction: column;
    /* margin-left: 20px; */
}
.vid-card-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: #287ce9 solid 1px; */
}
/* .vid-card-img >img{
    border-radius: 50%;
    background-size: cover;
    background-color: #287ce9;
} */
.vid-detail-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
    align-items: center;
    max-width: 960px;
}
#vid-dh-title{
    font-size: 20px;
    font-weight: bold;
}
.like-bttn{
    display: flex;
    flex-direction: row;
    border: none;
    border-radius: 50px;
    width: 80px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}
#thumb-up{
    width: 23px;
    margin-right: 5px;
}
#thumb-down{
    width: 23px;
    margin-right: 5px;
}
.like-btn-wrapper{
    display: flex;
    flex-direction: row;
    height: 37px;
    margin-left: 20px;
}

.feed-container{
    /* border: #287ce9 1px solid; */
    width: 400px;
    margin-left: 50px;
}

.detail-page-content{
    margin-bottom: 100px;
    width: 1000px;
}


.feed-vid-card{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.feed-vid-info{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 6px;
}
#feed-vid-title{
    font-weight: bold;
}
#feed-vid-owner{
    color: #8a8a8a;
    font-size: 12px;
}
#feed-vid-time{
    color: #8a8a8a;
    font-size: 12px;

}
.vid-btm-info{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.vid_card_name_wrapper{
    display: flex;
    flex-direction: row;
}
#verified_check{
    height: 13px;
    width: 13px;
    margin-top: 2px;
    margin-left: 2px;
}

.pfp_default{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 300;
    color:white
}

